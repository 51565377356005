.content > .letter {
  margin-left: 30px;
  font-size: 2em;
  color: black;
}

.content {
  display: flex;
  margin-left: 7%;
  margin-top: 7%;
}

.game {
  display: flex;
  justify-content: center;
}

@media all and (max-device-width: 480px) {

  .game {
    flex-direction: column;
    margin-top: 3%;
    align-items: center;
  }

  .content {
    margin: 0 auto;
    margin-top: 7%;
  }

  .content > .letter {
    font-size: 0.8em;
    padding: 0;
    margin-left : 7px;
    margin-right : 7px;
  }

}
