.hangman {
  text-align: center;
}

.btn-info {
  margin-left: 25%;
}

.header {
  display: flex;
  margin-top: 1%;
}

.title {
  margin-left: 40%;
}

@media all and (max-device-width: 480px) {
  .hangman {
    flex-direction: column;
  }

  .header {
    flex-direction: column;
    height: 15%;
  }

  .title {
    margin-left: 3%;
  }

  .btn-info {
    margin: 0 auto;
    width: 50%;
    margin-top: 10px;
  }
  
}
