@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'DINPro-Medium';
    src: url("./fonts/DINPro-Medium/DINPro-Medium.otf") format("opentype");
  }

  @font-face {
    font-family: 'DINPro-Regular';
    src: url("./fonts/DINPro-Regular/DINPro-Regular.woff") format("woff");
  }

body {
    font-family: "DINPro-Regular" !important;
    background-color: aliceblue;
    cursor: default !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "DINPro-Medium" !important; 
}

.bm-item-list ul li a:hover, .navbar a:hover  {
    color: black !important;
    transition: all .3s ease-in-out !important; 
}
